/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import TodoListForm from "./TodoListForm";
import TodoItem from "./TodoItem";
import './TodoList.css'
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import moment from "moment";
import { connect } from "react-redux";


const TodoList = ({ toDo, persona, usuario, setToDos, enableCheck=true, addTaskCallback, markAsDoneCallback, interesado }) => {
    const [labels, setLabels] = useState([])
    const [doneTasks, setDoneTasks] = useState([])
    const [undoneTasks, setUndoneTasks] = useState([])

    useEffect(() => {
        function filterTasks() {
            const todoTemp =  toDo || []
            setDoneTasks(todoTemp.filter((task) => task.done));
            setUndoneTasks(todoTemp.filter((task) => !task.done))
        }
        filterTasks()
    },
        [toDo])

    const markAsDone = id => {
        if(markAsDoneCallback !== undefined ){
            markAsDoneCallback(id)
        }else {
            // eslint-disable-next-line array-callback-return
            setToDos(toDo.map(todo => {
                if (todo.id === id) {
                    todo.done = true
                    return todo
                }
    
            }))
        }
    }

    const deleteItemHandler = (id) => {
        setToDos(toDo.filter((item, i) => item.id !== id));
    };

    return (
        <div className="todo-list mb-5">
            <TodoListForm setToDos={setToDos} persona={persona} labels={labels} setLabels={setLabels} addTaskCallback={addTaskCallback} interesado={interesado}></TodoListForm>
            {
                undoneTasks && undoneTasks.length > 0 ?
                    (<div className="mt-3">
                        <h2>Tareas Pendientes</h2>
                        <ul className="pl-0" style={{'listStyleType':'none'}}>
                            {
                                undoneTasks.filter(item => !item.done).map((item, idx) => {
                                    return (<li>
                                            <TodoItem enableCheck={enableCheck} {...item} onComplete={id => markAsDone(id)} onDelete={id => deleteItemHandler(id)}/>
                                        </li>)
                                })
                            }</ul>
                    </div>) :
                    (<div className="pt-3">
                        <div className="text-center">
                            <h2 className="mb-2">
                                <DotLottieReact
                                    loop autoplay
                                    style={{ width: "300px", height: "300px", margin: "auto" }}
                                    // src="https://lottie.host/ea249377-d74e-45c5-b480-60b5c2fc88eb/GspxJRxxhz.json" />
                                    src="https://lottie.host/27602164-6ebf-41b6-b99a-fe19dd171d77/qcF7qMxLIS.json" />
                                <br />
                                No hay tareas pendientes</h2>
                        </div>
                    </div>)
            }
        </div>
    )

}

export default TodoList

