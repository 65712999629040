import { Card, Form } from 'react-bootstrap'
import './TodoItem.css'
import { CriticalBadgeFromText } from '../badge/Badge';
import { useState } from 'react';
import moment from 'moment';

const TodoItem = ({
    id, title, description, prioridad, interesado, label, done,
    onComplete, dueDate, duedate, onDelete, enableCheck, interesadoId
}) => {
    const [doneItem, setDoneItem] = useState(done);
    const [hover, setHover] = useState(false);

    const changeStatus = ({ checked }) => {
        setDoneItem(checked);
        setTimeout(() => {
            onComplete(id);
            setDoneItem(false);
        }, 300);
    }
    
    const deleteItem = (id) => {
        onDelete(id);
    }

    return (
        <Card className={`todo-item my-3 ${doneItem ? "dismissed" : ""}`}>
            <div className='m-2 p-2'>
                <div className='d-inline-flex w-100'>
                    <div className="m-2"
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        style={{ cursor: "pointer" }}
                    >
                        {enableCheck && (
                            hover ? (
                                <i
                                    className="bi bi-trash text-danger"
                                    onClick={() => {
                                        const userConfirm = window.confirm("¿Está seguro que quiere eliminar la tarea?");
                                        if (userConfirm) {
                                            changeStatus();
                                        }
                                    }}
                                />
                            ) : (
                                <Form.Check
                                    type="checkbox"
                                    onChange={e => {
                                        const userConfirm = window.confirm("¿Está seguro que quiere eliminar la tarea?");
                                        if (userConfirm) {
                                            changeStatus(e.target);
                                        }
                                    }}
                                    checked={doneItem}
                                />
                            )
                        )}
                    </div>
                    <div className='w-100'>
                        <div className='title'>{title}</div>
                        <div>{description}</div>
                        <div className='d-inline-flex w-100 lighter'>
                            <div className='flex-fill'>
                                <i className='bi bi-calendar mr-2'></i>{moment(dueDate || duedate, 'YYYY-MM-DD').fromNow()}
                            </div>
                            <div className='flex-fill'>
                                <CriticalBadgeFromText value={prioridad} />
                            </div>
                            <div className='flex-fill'>
                                {label &&
                                    <div>
                                        <i className='bi bi-tag mr-2' />{label}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {!enableCheck && <span className="bi bi-x-lg pull-end" onClick={() => deleteItem(id)}></span>}
                </div>

                {/* Mostrar mensaje si no hay interesado */}
                <div className='flex-fill'>
                    {interesado ? (
                        <div>
                            <strong>Responsable:</strong> {interesado}
                        </div>
                    ) : (
                        <div>
                            <strong>Responsable:</strong> sin responsable designado
                        </div>
                    )}
                </div>

            </div>
        </Card>
    );
}

export default TodoItem;
